import { Store, stores } from '@strategies/stores';
import { action, computed, observable, makeObservable } from 'mobx';


export default class UserStore extends Store {

    constructor() {
        super();
        makeObservable(this);
    }

    @observable
    name: string = '';

    @action
    setName(name: any) {
        this.name = name;
    }

    @observable
    email: string = '';

    @action
    setEmail(email: any) {
        this.email = email;
    }

    @observable
    positionTitle: string = '';

    @action
    setPositionTitle(positionTitle: any) {
        this.positionTitle = positionTitle;
    }

    @observable
    discipline: string = '';

    @action
    setDiscipline(discipline: any) {
        this.discipline = discipline;
    }





}
