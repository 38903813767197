// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
    apiKey: "AIzaSyB9ocWY2fOONoc761Y6BxgeaS3qWMlRj-s",
    authDomain: "sasakiu-ebc81.firebaseapp.com",
    projectId: "sasakiu-ebc81",
    storageBucket: "sasakiu-ebc81.appspot.com",
    messagingSenderId: "783288251417",
    appId: "1:783288251417:web:61d6ebd15e3b6612cb2688",
    measurementId: "G-QP486K9622"
};