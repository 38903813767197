import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Landing } from "./components/Landing/Landing";
import { observer } from "mobx-react";
import { CourseSelection } from "./components/CourseSelection/CourseSelection";
import { useStores } from "@strategies/stores";
import { Stores } from "./index";
import { ThankYou } from "./components/ThankYou/ThankYou";
import { Closed } from "./components/Closed/Closed";


export const App = observer(() => {
    const { app, user } = useStores() as Stores;

    return (
        <div className="App">
            {/*{(!app.primaryInfoCollected || !app.secondaryInfoCollected) && <Landing/>}
            {app.primaryInfoCollected && app.secondaryInfoCollected &&  !app.submitBtnClicked && <CourseSelection/>}
            {app.submitBtnClicked && <ThankYou/>}
            {app.getPrivateMode()}*/}
            <Closed/>
        </div>
    );
});