import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { App } from './App';
import reportWebVitals from './reportWebVitals';

import { GA_ID, HEAP_ID } from './config';
import { register } from "@strategies/stores";
import AppStore from "./stores/AppStore";
import UserStore from "./stores/UserStore";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "./firebaseConfig";
import { getAnalytics } from "firebase/analytics";

register({
    app: new AppStore(),
    user: new UserStore(),

});

const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);

export type Stores = {app:AppStore, user: UserStore};

//if (process.env.REACT_APP_ENV === 'production') {
    if (GA_ID  && window.gtag) {
        window.gtag('config', GA_ID);
    }
    if (HEAP_ID && !window.heap.loaded) {
        window.heap.load(HEAP_ID);
    }
//}

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <App/>

    </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
