import { observer } from "mobx-react";
import { MdEditNote } from 'react-icons/md';


export const Closed = observer(() => {

    return <div className="ThankYou">
        <div className={"section"}>
            <div className="title">
                Sasaki U <MdEditNote/>
            </div>

            <div className={"content"}>
                <h3>The Sasaki U Survey is now closed.</h3>
                <p>For more information or additional questions, please reach out to:<br/>Tamar Warburg, Varda Halidy,
                    and Alison
                    Nash</p>
            </div>
        </div>


    </div>;
});