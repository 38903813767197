import { Store } from '@strategies/stores';
import { action, computed, observable, makeObservable } from 'mobx';
import { doc, getFirestore, getDoc, setDoc, collection, getDocs } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import { UserCourse } from "../model/UserCourse";

const data = require('../data/data.json');

export default class AppStore extends Store {

    constructor() {
        super();
        makeObservable(this);
    }

    onRegister() {

        console.log('onRegister'); // -> 5
        this.getModulesAndCourses();
        this.makeModuleFilters();
    }

    @observable
    height: number = window.innerHeight;

    @observable
    modules: string[] = [];

    @observable
    courses: string[] = [];

    @observable
    moduleColor: any = {};

    @observable
    nameError: string = "";

    @action
    setNameError(val: string) {
        this.nameError = val;
    }

    @observable
    emailError: string = "";

    @action
    setEmailError(val: string) {
        this.emailError = val;
    }

    @observable
    primaryInfoCollected: boolean = false;

    @action
    setPrimaryInfoCollected(val: boolean) {
        this.primaryInfoCollected = val;
    }

    @observable
    secondaryInfoCollected: boolean = false;

    @action
    setSecondaryInfoCollected(val: boolean) {
        this.secondaryInfoCollected = val;
    }

    @observable
    submitBtnClicked: boolean = false;

    @action
    setSubmitBtnClicked(val: boolean) {
        this.submitBtnClicked = val;
    }

    @observable
    selectedOnly: boolean = false;

    @action
    setSelectedOnly(bySelection: boolean = true) {
        this.selectedOnly = bySelection;
    }


    saveToDB(userCourseObj: UserCourse) {
        this.save(userCourseObj);
    }

    readonly id: string = uuidv4();

    async save(userCourseObj: UserCourse) {
        await setDoc(doc(getFirestore(), 'responses', this.id), Object.assign({}, userCourseObj));
    }

    loadData() {
        return data;
    }

    async getData() {

        let peopleEmail: string[] = [];
        const querySnapshot = await getDocs(collection(getFirestore(), "responses"));
        querySnapshot.docs.forEach((doc) => {
            const email = doc.data().email;
            console.log(email);
            if (!peopleEmail.includes(email)) {
                peopleEmail.push(email);
            } else {
                console.log('duplicate: ', email);
            }
        });
        console.log('peopleEmail length: ', peopleEmail.length);
    }

    getPrivateMode() {
        if (window.location && window.location.search) {
            let searchParams = new URLSearchParams(window.location.search);
            let privateMode = searchParams.get("mode") === "private";
            if (privateMode) {
                this.getData();
            }
        } else {
            console.log('no params...');
            return false
        }
    }


    getModulesAndCourses() {
        data.map((x: any) => {
            const courseName = x['Course Name'];
            const moduleName = x['Module Name'];
            const color = x['Color'];
            const courseModuleName = courseName + "_" + moduleName;
            if (!this.modules.includes(moduleName)) {
                this.modules.push(moduleName)
                this.moduleColor[moduleName] = color
            }
            if (!this.courses.includes(courseModuleName)) {
                this.courses.push(courseModuleName)
            }
        })
    }

    @observable
    moduleFilter: any = {};

    @action
    setModuleFilter(module: string, val?: boolean) {
        this.moduleFilter[module] = !this.moduleFilter[module];
    }

    //object with key: module name, val: boolean
    makeModuleFilters() {
        this.modules.map((x) => {
            this.setModuleFilter(x, true);
        })
    }

    @observable
    courseFilter: any = {};

    @action
    setCourseFilter(module: string, val?: boolean) {
        this.courseFilter[module] = !this.courseFilter[module];
    }

    //object with key: courseModule name, val: boolean
    makeCourseFilters() {
        this.courses.map((x) => {
            this.setCourseFilter(x, false);
        })
    }

    @observable
    totalSelectedHours: number = 0;

    @action
    setTotalSelectedHours(val: number, operator: boolean) {
        if (operator) {
            this.totalSelectedHours = this.totalSelectedHours + val;
        } else {
            this.totalSelectedHours = this.totalSelectedHours - val;
        }

    }

    @observable
    errorFieldsMessage: any = {};

    @action
    setErrorFieldsMessage(module: string, val?: string) {
        this.errorFieldsMessage[module] = val;
    }

    //object with key: courseModule name, val: boolean
    makeErrorFields() {
        const errorFields = ['name', 'email', 'positionTitle', 'discipline'];
        errorFields.map((x: string) => {
            this.setErrorFieldsMessage(x, "");
        })
    }


}
